<template>
  <div>
<!--    xs md lg xl 2xl-->
    <form method="post" @submit.prevent="change">

      <div class="mb-2">
        <label>Old Password</label>
        <input-text label="Old Password" password-eye
                    :variant="this.hasFormError('old_password') ? 'danger':'primary'"
                    type="password"
                    placeholder="Type your old password" size="md" class="w-full" v-model="password_data.old_password"/>
        <show-error :errors="formErrors" name="old_password"></show-error>
      </div>

      <div class="mb-3">
        <label>New Password Password</label>
        <input-text label="Old Password" password-eye
                    type="password"
                    placeholder="Type a new password" size="md" class="w-full" v-model="password_data.new_password" />
        <show-error :errors="formErrors" name="new_password"></show-error>
      </div>

      <div class="mb-3 text-center w-full">
        <btn variant="info">Change Password</btn>
      </div>


    </form>

  </div>
</template>

<script>
import Container from "../../layouts/container";
import InputText from "../../../components/input/input-text";
import Btn from "../../../components/btn";
import ShowError from "../../../components/show-error";
export default {
  name: "change-password",
  data: {
    loading: true,
    password_data: { old_password: '', new_password: '', },
  },
  components: {ShowError, Btn, InputText, Container},
  methods: {
    change( ){

      this.$store.dispatch( 'student/changePassword', {
        data: this.password_data,
        success: ( { data } ) => {

          this.setTokenAndRedirectToNext(data);
        },
        has_errors: ( errors ) => this.formErrors = errors
      });
    },
  },
  created() {
    this.$emit( 'loaded' );
  }
};
</script>

<style scoped>
</style>
