<template>
  <ul class="text-red-700">
    <li v-for="err in form_errors" :key="err">{{err}}</li>
  </ul>
</template>

<script>
export default {
  name: "show-error",
  props: {
    errors: Object, name: String
  },
  computed: {
    form_errors:{
      get( ) {
        const errors = this.errors || this.formErrors;

        if ( errors[this.name] ){
          return Array.isArray( errors[this.name] ) ? errors[this.name]:[ ];
        }

        return [];
      },
      set( errs ){
        this.formErrors = errs;
      }
    }
  },
  watch: {
    formErrors( errors ){


      this.form_errors = errors;
    }
  }

}
</script>

<style scoped>

</style>